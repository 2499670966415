<template lang="html">
  <div class="map-loading-indicator" :class="{'map-loading-indicator-visible': loadingData, expanded: rightExpanded, wide: rightWide}">
    <FaIcon :icon="icon" size="3x" spin />
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {faSync} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon as FaIcon} from '@fortawesome/vue-fontawesome'

export default {
  components: {
    FaIcon
  },
  props: {
    rightExpanded: Boolean,
    rightWide: Boolean
  },
  computed: {
    ...mapState('dataLoading', [
      'runningCounts'
    ]),
    icon () {
      return faSync
    },
    loadingData () {
      return [
        'orgUnit.cultivations',
        'orgUnit.fieldCultivationInfo',
        'orgUnit.geodata',
        'orgUnit.simplified-geometries',
        'nutrientMaps.forField',
        'zoneMaps.details'
      ].some(id => this.runningCounts[id])
    }
  }
}
</script>

<style lang="scss" scoped>
.map-loading-indicator {
  position: absolute;
  top: 0;
  right: 40px;
  transform: translate(-50%, 50%);
  color: #fff;

  opacity: 0;
  transition: opacity 0.3s, transform 0.3s;

  &.map-loading-indicator-visible {
    opacity: 0.75;
  }

  &.expanded {
    right: 400px;

    &.wide {
      right: 800px;
    }
  }
}
</style>
