<template>
  <div>
    <button
      :title="description" type="button"
      class="button"
      :class="classes" :disabled="disabled"
      @click.stop.prevent="$emit('click')"
    >
      <FontAwesomeIcon class="icon" :icon="autoIcon" />
    </button>
  </div>
</template>

<script>
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {faSave, faTrashAlt, faTimesCircle} from '@fortawesome/free-regular-svg-icons'

export default {
  components: {
    FontAwesomeIcon
  },
  props: {
    icon: Object,
    disabled: Boolean,
    description: String,
    save: Boolean,
    remove: Boolean
  },
  computed: {
    autoIcon () {
      if (this.icon) return this.icon

      if (this.save) {
        return faSave
      } else if (this.remove) {
        return faTrashAlt
      } else {
        return faTimesCircle
      }
    },
    classes () {
      return {
        'btn-success': this.save,
        'btn-danger': this.remove
      }
    }
  }
}
</script>

<style scoped>
.button {
  position: relative;
  /*background-color: white;*/
  border: 1px solid black;
  border-radius: 50%;
  margin: 4px;
  padding: 0;
  width: 40px;
  height: 40px;
  outline: none;
}
.button:hover {
  cursor: pointer;
  border: 2px solid #9dd2ff;
  background-color: white;
}
.btn-success {
  background-color: #86df86;
}
.btn-success:hover {
  background-color: #97fa97;
  border: 2px solid #1a94fe;
}
.btn-danger {
  background-color: #ea7471;
}
.btn-danger:hover {
  background-color: #ea8988;
  border: 2px solid #1a94fe;
}
.icon {
  text-align: center;
  color: black;
  font-size: 1.5em;
  padding-top: 2px;
  width: 24px;
  height: 24px;
}
</style>
