<template lang="html">
  <IxMapScope
    class="field-record-system" :class="{
      'left-sidebar-expanded': leftExpanded,
      'right-sidebar-expanded': rightExpanded,
      'right-sidebar-wide': wideRightSidebar
    }"
  >
    <div class="columns">
      <div class="map-container" style="position: relative;">
        <FrsMap ref="map" />
        <MapLoadingIndicator :right-expanded="rightExpanded" :right-wide="wideRightSidebar" />
      </div>

      <FrsLoadingIndicator v-if="mainPageLoading" small :loading="mainPageLoading" />
      <SlidingSidebar v-model="leftExpanded" direction="left">
        <FrsErrorReportForm />
        <router-view name="left" />
        <FrsLoadingIndicator :requests="['frs.subTree']" />
      </SlidingSidebar>

      <SlidingSidebar
        v-model="rightExpanded"
        direction="right"
        :wide="wideRightSidebar"
      >
        <router-view />
        <FrsLoadingIndicator :loading="loadingData" :requests="['frs.subTree']" />
      </SlidingSidebar>
    </div>
    <DebugMenu />

    <Portal to="modals">
      <ConverterSelection v-model="showConverterModal" />
    </Portal>

    <Portal to="modals">
      <FrsFirstVisitModal />
    </Portal>

    <PortalTarget name="modals" multiple />
  </IxMapScope>
</template>

<script>
import {mapState, mapMutations, mapActions} from 'vuex'

import {mapFormFields} from '@helpers/vuex'

import FrsErrorReportForm from './FrsErrorReportForm'
import FrsFirstVisitModal from './FrsFirstVisitModal'
import ConverterSelection from '@components/download/ConverterSelection'

import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'
import MapLoadingIndicator from '@frs/components/map/MapLoadingIndicator'

import SlidingSidebar from './components/SlidingSidebar'
import FrsMap from './components/FrsMap'
import IxMapScope from '@components/map/IxMapScope'
import DebugMenu from './DebugMenu'

export default {
  provide () {
    return {
      getMap: () => this.$refs.map.$refs.map.map
    }
  },
  components: {
    FrsErrorReportForm,
    FrsFirstVisitModal,
    ConverterSelection,
    DebugMenu,
    SlidingSidebar,
    IxMapScope,
    FrsMap,
    FrsLoadingIndicator,
    MapLoadingIndicator
  },
  props: {
    year: Number
  },
  data () {
    return {
      leftExpanded: true,
      rightExpanded: true
    }
  },
  computed: {
    ...mapState('fieldRecordSystem', {
      expanded: state => state.ui.view.expanded && !state.map.editing.active,
      loadingData: state => state.ui.loadingData,
      mainPageLoading: state => state.ui.mainPageLoading,
      harvestYear: state => state.userSettings.harvestYear
    }),
    wideRightSidebar () {
      return this.expanded || (this.$route.meta && this.$route.meta.wide)
    },
    ...mapFormFields('fieldRecordSystem/download', [
      'ui.showConverterModal'
    ])
  },
  methods: {
    ...mapMutations('fieldRecordSystem', [
      'setRightSidebarCollapsed',
      'setLeftSidebarCollapsed'
    ]),
    ...mapActions('fieldRecordSystem', [
      'setHarvestYear'
    ])
  },
  watch: {
    rightExpanded () {
      this.setRightSidebarCollapsed(!this.rightExpanded)
    },
    leftExpanded () {
      this.setLeftSidebarCollapsed(!this.leftExpanded)
    },
    harvestYear: {
      immediate: true,
      handler (year) {
        if (!this.year) {
          this.$router.push({name: 'home', params: {year}})
        } else if (year !== this.year) {
          const {name, params} = this.$route
          this.$router.push({name, params: {...params, year}})
        }
      }
    },
    year (year) {
      if (year) {
        this.setHarvestYear(year)
      } else {
        throw new Error('no year!')
      }
    }
  }
}
</script>

<style lang="scss">
@import './styles/field-record-system.scss';
</style>

<style lang="scss" scoped>
.field-record-system {
  position: relative;
  display: flex;
  height: 100%;

  .columns {
    flex: 1;
    display: flex;
    position: relative;
    overflow: hidden;
  }

  .map-container {
    flex: 1;
    height: 100%;
    position: relative;
  }
}
</style>
