<template lang="html">
  <div :class="`ol-control ol-location-search ${collapsedStyle}`">
    <div class="input-group">
      <text-input
        v-if="!isCollapsed" v-focus
        list-id="suggestionsId"
        :placeholder="placeholder" :value="value"
        @submit="submit" @input="input"
      />
      <span class="input-group-btn">
        <a class="btn btn-default" type="button" @click.prevent="submit"><i class="fa fa-search" /></a>
      </span>
      <span v-if="!isCollapsed" class="input-group-btn">
        <a class="btn btn-default chevron-container" type="button" @click.prevent="collapseInput"><i class="fa fa-chevron-left" /></a>
      </span>
      <datalist id="suggestionsId">
        <option v-for="(suggestion, i) in suggestions" :key="i" :value="suggestion.address.formattedAddress + ', ' + suggestion.address.adminDistrict2">
          {{ suggestion.address.formattedAddress + ', ' + suggestion.address.adminDistrict2 }}
        </option>
      </datalist>
    </div>
  </div>
</template>

<script>

import TextInput from './TextInput'

export default {
  directives: {
    focus: {
      inserted: function (el) {
        el.getElementsByClassName('form-control')[0].focus()
      }
    }
  },
  components: {
    TextInput
  },
  props: {
    value: String,
    suggestions: Array,
    placeholder: String,
    id: String
  },
  data () {
    return {
      isCollapsed: false
    }
  },
  computed: {
    collapsedStyle () {
      return !this.isCollapsed ? '' : 'ol-location-search-collapsed'
    }
  },
  methods: {
    input (value) {
      this.$emit('input', value)
    },
    submit () {
      if (this.isCollapsed) {
        this.isCollapsed = false
        // this.$nextTick(() => {
        //   window.$refs['locationInput'].focus()
        // })
      } else {
        this.$emit('submit')
      }
    },
    collapseInput () {
      this.isCollapsed = true
    }
  }
}
</script>

<style lang="scss" scoped>
  .ol-location-search {
    width: 20%;
    z-index: 0;
  }
  .ol-location-search-collapsed {
    width: 0 !important;
    z-index: 0;
  }
  .chevron-container {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
    width: 20px;
  }
</style>
