<template lang="html">
  <DebugModal ref="modal" :key-code="121" title="Prototype Admin Functions">
    <p class="lead">
      Translation
    </p>
    <button class="btn btn-default" @click="toggleDebugFlag('localization')">
      {{ localization ? 'Hide translations' : 'Show translations' }}
    </button>
    <p class="lead">
      ASK
    </p>
    <button class="btn btn-default" @click="resetFrs">
      Reset UI
    </button>
  </DebugModal>
</template>

<script>
import {mapState, mapMutations} from 'vuex'

import DebugModal from './components/testing/DebugModal'

export default {
  components: {
    DebugModal
  },
  computed: {
    ...mapState({
      localization: state => state.debug.localization
    })
  },
  methods: {
    ...mapMutations([
      'toggleDebugFlag'
    ]),
    ...mapMutations('fieldRecordSystem', [
      'setRightView',
      'setDataLoading'
    ]),
    resetFrs () {
      this.setRightView('default')
      this.setDataLoading(false)
      this.$router.push({name: 'home'})
      this.$refs.modal.show = false

      for (const el of document.querySelectorAll('.modal-backdrop')) {
        el.remove()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
