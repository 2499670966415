<template lang="html">
  <div>
    <TextInput
      v-model="fileName" required
      disabled name="fileName"
    >
      <template #label>
        <IxRes>Common.SR_Common.FileName</IxRes>
      </template>
    </TextInput>

    <TextInput v-model="description" name="description">
      <template #label>
        <IxRes>Common.SR_Common.Description</IxRes>
      </template>
    </TextInput>
  </div>
</template>

<script>
import {mapState} from 'vuex'

import {mapFormFields} from '@helpers/vuex'

import TextInput from '@components/forms/TextInput'

export default {
  components: {
    TextInput
  },
  computed: {
    ...mapFormFields('fieldRecordSystem/download', [
      'description',
      'fileName'
    ]),
    ...mapState('fieldRecordSystem/download', {
      selectedConverter: state => state.selectedConverter
    })
  }
}
</script>

<style lang="css">
</style>
