<template lang="html">
  <div class="error-report-container">
    <ErrorReportForm class="foo" :context="context" />
  </div>
</template>

<script>
import ErrorReportForm from '@components/error-reporting/ErrorReportForm'

export default {
  components: {
    ErrorReportForm
  },
  methods: {
    context () {
      const {
        navigation: {
          location
        },
        ui: {
          view: {
            right: rightView
          },
          workflow
        },
        userSettings: {
          harvestYear
        },
        data: {
          field: fieldData
        }
      } = this.$store.state.fieldRecordSystem

      const {permissions} = this.$store.state

      const context = {
        harvestYear,
        location,
        rightView,
        workflow
      }

      const entityLookup = this.$store.getters['fieldRecordSystem/navigation/entityLookup']
      const parentLookup = this.$store.getters['fieldRecordSystem/navigation/parentLookup']

      context.orgTrace = []

      let currentOrgUnitId = location.orgUnitId
      while (currentOrgUnitId) {
        const orgUnit = entityLookup[currentOrgUnitId]

        context.orgTrace.push(orgUnit ? orgUnit.name : 'ERROR')
        currentOrgUnitId = parentLookup[currentOrgUnitId]
      }

      const defaultContextData = [context, '#### Permissions', permissions]

      if (location.fieldId) {
        const {
          name,
          geometry,
          context: fieldHistoryContext
        } = fieldData[location.fieldId]

        context.fieldData = {
          name,
          fieldHistoryContext
        }

        return defaultContextData.concat(['#### Field geometry', `\`\`\`json\n${JSON.stringify(geometry)}\n\`\`\``])
      }

      return defaultContextData
    }
  }
}
</script>

<style lang="scss" scoped>
.error-report-container {
  position: absolute;
  left: 0;
  top: 0;
  padding: 0.5em;
}
</style>
