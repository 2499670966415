import {cloneDeep, isEqual} from 'lodash'
import kinks from '@turf/kinks'
import {unByKey} from 'ol/Observable'
import Draw from 'ol/interaction/Draw'
import Modify from 'ol/interaction/Modify'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import Polygon from 'ol/geom/Polygon'
import LinearRing from 'ol/geom/LinearRing'
import {shiftKeyOnly, singleClick} from 'ol/events/condition'

import {defaultAttribution} from '@helpers/openlayers/layers'
import {geoJsonFormat} from '@helpers/openlayers/features'
import {editing, holes, inactiveStyle} from './styles'

export default {
  inject: [
    'getMap'
  ],
  props: {
    draw: {
      type: String,
      validator: type => ['Polygon', 'LineString', 'Point'].indexOf(type) !== -1
    },
    value: [Array, Object],
    emitHistoryEvents: Boolean,
    noValidation: Boolean
  },
  data () {
    return {
      history: {
        undo: [],
        redo: []
      },
      interacting: false,
      cancelled: false,
      fieldLayerStyle: null
    }
  },
  computed: {
    style () {
      return editing
    },
    inactive () {
      return inactiveStyle
    },
    geoJsonFeatures () {
      return Array.isArray(this.value) ? this.value : [this.value]
    },
    olFeatures () {
      return this.geoJsonFeatures.map(f => geoJsonFormat.readFeature(f))
    }
  },
  methods: {
    init () {
      this.source = new VectorSource({
        attributions: [defaultAttribution],
        features: this.olFeatures
      })
      this.holeSource = new VectorSource()

      const fieldLayer = this.map
        .getLayers()
        .getArray()
        .find(layer => layer.get('id') === 'fields')

      // the edit interaction is used everywhere so there is no guaranty a field layer exists
      if (fieldLayer) {
        this.fieldLayerStyle = fieldLayer.getStyle()
        fieldLayer.setStyle(this.inactive)
      }

      this.layer = new VectorLayer({
        source: this.source,
        style: this.style
      })
      this.map.getLayers().push(this.layer)

      this.modifyInteraction = new Modify({
        source: this.source,
        deleteCondition: this.deleteCondition
      })
      this.map.addInteraction(this.modifyInteraction)
      this.listeners.push(this.modifyInteraction.on('modifystart', this.onInteractionStart))
      this.listeners.push(this.modifyInteraction.on('modifyend', this.commitSourceChanges))

      this.recreateHoleInteraction()

      if (this.draw) {
        this.drawInteraction = new Draw({
          type: this.draw,
          source: this.source,
          freehandCondition: () => false
        })
        this.map.addInteraction(this.drawInteraction)

        this.listeners.push(this.drawInteraction.on('drawstart', this.onInteractionStart))
        this.listeners.push(this.drawInteraction.on('drawend', this.commitSourceChanges))
      }

      document.addEventListener('keydown', this.onKeyDown)
    },
    recreateHoleInteraction () {
      if (this.holeDrawInteraction) {
        this.holeListeners.forEach(x => unByKey(x))
        this.map.removeInteraction(this.holeDrawInteraction)
      }

      this.holeDrawInteraction = new Draw({
        source: this.holeSource,
        type: 'Polygon',
        style: holes,
        condition: this.holeDrawStartCondition,
        freehandCondition: () => false
      })
      this.map.addInteraction(this.holeDrawInteraction)

      this.holeListeners.push(this.holeDrawInteraction.on('drawstart', this.onInteractionStart))
      this.holeListeners.push(this.holeDrawInteraction.on('drawend', this.onHoleDrawEnd))
    },
    holeDrawStartCondition (event) {
      const features = this.source.getFeaturesAtCoordinate(event.coordinate)

      this.existingPolygon = features.find(f => f.getGeometry().getType() === 'Polygon')

      return /* alt */shiftKeyOnly(event) && this.existingPolygon
    },
    onInteractionStart () {
      this.makeSnapshot()
      this.interacting = true

      this.cancelled = false
    },
    onHoleDrawEnd (event) {
      const geometry = event.feature.getGeometry()
      if (this.existingPolygon && geometry.getType() === 'Polygon') {
        const coordinates = geometry.getCoordinates()[0]

        this.existingPolygon.getGeometry().appendLinearRing(new LinearRing(coordinates))
      }

      this.commitSourceChanges()
      this.recreateHoleInteraction()
    },
    onKeyDown (event) {
      if (event.keyCode === 27 && this.interacting) { // esc
        this.cancelled = true
        if (this.drawInteraction) {
          try {
            this.drawInteraction.finishDrawing()
          } catch (error) {
            console.error(error)
          }
        }
        try {
          this.holeDrawInteraction.finishDrawing()
        } catch (error) {
          console.error(error)
        }
      }
    },
    makeSnapshot () {
      this.history.undo.push(cloneDeep(this.value))
      this.history.redo = []
    },
    undo (discard) {
      if (this.history.undo.length) {
        const snapshot = this.history.undo.pop()

        if (!discard) {
          this.history.redo.unshift(cloneDeep(this.value))
        }

        if (isEqual(snapshot, this.value)) {
          this.rebuildSource()
        } else {
          this.$emit('input', snapshot)
        }
      }
    },
    redo () {
      if (this.history.redo.length) {
        const snapshot = this.history.redo.shift()
        this.history.undo.push(cloneDeep(this.value))

        if (isEqual(snapshot, this.value)) {
          this.rebuildSource()
        } else {
          this.$emit('input', snapshot)
        }
      }
    },
    deleteCondition (event) {
      this.maybeDeleteLinearRing(event)
      return singleClick(event)// && condition.shiftKeyOnly(event)
    },
    maybeDeleteLinearRing (event) {
      if (!(singleClick(event)/* && condition.shiftKeyOnly(event) */)) {
        return
      }

      const clickedPixel = this.map.getPixelFromCoordinate(event.coordinate)
      const equalToClickedPoint = coord => {
        const pixel = this.map.getPixelFromCoordinate(coord)
        return Math.sqrt(Math.pow(pixel[0] - clickedPixel[0], 2) + Math.pow(pixel[0] - clickedPixel[0], 2)) <= 10
      }

      const isPolygon = feature => feature.getGeometry().getType() === 'Polygon'
      // const hasHoles = feature => feature.getGeometry().getLinearRingCount() > 1

      const shouldBeRemoved = ring => {
        const coords = ring.getCoordinates()
        return coords.length === 4 && // is triangle
          coords.some(equalToClickedPoint) // clicked point is part of the ring
      }
      // inverted form is easier to pass into array methods
      const shouldNotBeRemoved = (ring, i) => !shouldBeRemoved(ring, i)

      const allFeatures = this.source.getFeatures()
      // could be made more efficient without chained filters, but more readable this way
      const relevantPolygons = allFeatures
        .filter(isPolygon)
        // .filter(hasHoles)
        .filter(feature => feature.getGeometry().getLinearRings().some(shouldBeRemoved))

      if (!relevantPolygons.length) {
        return
      }

      const featuresToRemove = []

      for (const feature of relevantPolygons) {
        const removeRings = feature.getGeometry().getLinearRings().map(shouldBeRemoved)

        if (removeRings[0]) {
          featuresToRemove.push(feature)
          continue
        }

        const remainingRings = feature.getGeometry().getLinearRings()
          .filter(shouldNotBeRemoved)
          .map(ring => ring.getCoordinates())
        feature.setGeometry(new Polygon(remainingRings))
      }

      const remainingFeatures = allFeatures.filter(f => !featuresToRemove.includes(f))

      this.source.clear()
      this.source.addFeatures(remainingFeatures)

      // this.commitSourceChanges()
    },
    commitSourceChanges () {
      this.$nextTick(() => {
        const features = this.source.getFeatures().map(f => geoJsonFormat.writeFeatureObject(f))

        const nonPointFeatures = features.filter(f => f.geometry.type !== 'Point')

        if (this.cancelled) {
          this.undo(true)
        } else if (!this.noValidation && nonPointFeatures.some(f => kinks(f).features.length > 0)) {
          this.$emit('warning', 'invalid geometry')

          this.undo(true)
        } else {
          this.$emit('input', Array.isArray(this.value) ? features : features[0])
        }

        this.interacting = false
        this.cancelled = false
      })
    },
    rebuildSource () {
      this.source.clear()
      this.holeSource.clear()
      this.source.addFeatures(this.geoJsonFeatures.map(feature => geoJsonFormat.readFeature(feature)))
    }
  },
  render () {
    return null
  },
  watch: {
    value: 'rebuildSource',
    features: 'rebuildSource'
  },
  created () {
    this.listeners = []
    this.holeListeners = []

    this.getMap().then(map => {
      this.map = map
      this.init()
      // setTimeout(this.init, 2000)
    })
  },
  beforeDestroy () {
    document.removeEventListener('keydown', this.onKeyDown)

    const fieldLayer = this.map
      .getLayers()
      .getArray()
      .find(layer => layer.get('id') === 'fields')

    if (fieldLayer && this.fieldLayerStyle) {
      fieldLayer.setStyle(this.fieldLayerStyle)
    }

    if (this.drawInteraction) {
      this.map.removeInteraction(this.drawInteraction)
    }
    this.map.removeInteraction(this.modifyInteraction)
    this.map.removeInteraction(this.holeDrawInteraction)
    this.map.getLayers().remove(this.layer)

    this.listeners.forEach(unByKey)
    this.holeListeners.forEach(unByKey)
  }
}
