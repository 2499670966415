<template lang="html">
  <div class="hole-hint-control">
    <div class="hole-hint">
      <IxRes>frs.geometryEditing.hints.holeDrawing</IxRes>
      <IxRes>frs.geometryEditing.hints.vertexRemoval</IxRes>
    </div>
  </div>
</template>

<script>
import ControlMixin from '@components/map/mixins/ControlMixin'

export default {
  mixins: [
    ControlMixin
  ]
}
</script>

<style lang="scss" scoped>
.hole-hint-control {
  position: absolute;
  bottom: 90px;
  width: 100%;

  display: flex;
  justify-content: center;

  .hole-hint {
    color: white;
    font-size: 1.2em;
    background-color: rgba(0, 0, 0, 0.3);
    padding: 8px;
    border-radius: 6px;
    pointer-events: none;

    display: flex;
    flex-direction: column;
  }
}
</style>
