<template lang="html">
  <div class="map-wrapper">
    <IxMap
      ref="map"
      :extent="focusExtent"
      :translations="translations"
    />

    <IxBingLayer imagery="hybrid" />

    <DefaultMapLayers v-if="showDefaultLayers" />

    <LocationSearchControl />

    <QuickModeControl v-if="editing && flavor === 'quick'" />
    <template v-else-if="!editing && defaultInteractionsEnabled">
      <FrsFieldNavigationInteraction />
    </template>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {mapResources} from '@helpers/vuex'

import IxMap from '@components/map/IxMap'
import IxBingLayer from '@components/map/IxBingLayer'
import LocationSearchControl from '@components/map/controls/LocationSearchControl'
import FrsFieldNavigationInteraction from '@frs/components/map/FrsFieldNavigationInteraction'
import QuickModeControl from '@frs/components/geometry-editing/quick-mode-editing/QuickModeControl'

import DefaultMapLayers from './map/DefaultMapLayers'

export default {
  components: {
    IxMap,
    IxBingLayer,
    DefaultMapLayers,
    LocationSearchControl,
    FrsFieldNavigationInteraction,
    QuickModeControl
  },
  data () {
    return {
      nonReactive: {
        cancellations: {}
      }
    }
  },
  computed: {
    ...mapState('fieldRecordSystem/navigation', [
      'location'
    ]),
    ...mapState('fieldRecordSystem/map', [
      'showDefaultLayers',
      'defaultInteractionsEnabled'
    ]),
    ...mapState('fieldRecordSystem/map', {
      bingVisible: state => state.bing.visible,
      editing: state => state.editing.active,
      flavor: state => state.editing.flavor,
      focusExtent: state => state.focusExtent
    }),
    ...mapResources([
      '@frs.SR_FieldRecordSystem'
    ]),
    translations () {
      return {
        zoomToExtent: this.SR_FieldRecordSystem.ZoomMap
      }
    }
  }
}
</script>

<style lang="css">
.map-wrapper {
  width: 100%;
  height: 100%;
}
</style>
