<template lang="html">
  <SelectInteraction
    v-model="selection"
    :features="fieldFeaturesForCurrentOrgUnit"
    :selection-style="selectionStyle"
    :hover-style="hoverStyle"
    :layer-filter="layer => layer.get('id') === 'fields'"
    id-key="id"
  />
</template>

<script>
import {mapState, mapGetters} from 'vuex'

import SelectInteraction from '@components/map/interactions/SelectInteraction'
import {hoverStyle, selectionStyle} from '@frs/map-styles'

export default {
  components: {
    SelectInteraction
  },
  computed: {
    ...mapState('fieldRecordSystem/navigation', [
      'location',
      'simplifiedGeometries'
    ]),
    ...mapGetters('fieldRecordSystem/navigation', [
      'subTreeFields'
    ]),
    fieldFeaturesForCurrentOrgUnit () {
      return this.subTreeFields.map(({id, name}) => ({
        type: 'Feature',
        properties: {id, name},
        geometry: this.simplifiedGeometries[id]
      })).filter(x => x.geometry)
    },
    selection: {
      get () {
        return this.location.fieldId
      },
      set (id) {
        if (id) {
          this.$router.push({name: 'fieldDashboard', params: {fieldId: id}})
        } else {
          const {orgUnitId} = this.location
          if (orgUnitId) {
            if (this.$route.name !== 'orgDashboard') {
              this.$router.push({name: 'orgDashboard', params: {orgUnitId}})
            }
          } else if (this.$route.name !== 'home') {
            this.$router.push({name: 'home'})
          }
        }
      }
    },
    selectionStyle () {
      return selectionStyle
    },
    hoverStyle () {
      return hoverStyle
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
