import {unByKey} from 'ol/Observable'

import {makeWFSLayer} from '@helpers/openlayers/layers'

export default {
  inject: [
    'getMap'
  ],
  props: {
    url: String,
    typeNames: String,
    layerStyle: {},
    maxResolution: {
      type: Number,
      default: Infinity
    },
    debug: Boolean
  },
  data () {
    return {
      resolution: null
    }
  },
  computed: {
    active () {
      return this.resolution <= this.maxResolution
    }
  },
  methods: {
    recreate () {
      const {url, typeNames, layerStyle: style} = this

      if (this.layer) {
        this.map.getLayers().remove(this.layer)
        this.layer = null
      }

      if (this.resolution > this.maxResolution) return

      this.layer = makeWFSLayer({url, typeNames, style})

      this.map.getLayers().push(this.layer)
    }
  },
  watch: {
    resolution (resolution) {
      if ((this.layer && resolution > this.maxResolution) || (!this.layer && resolution <= this.maxResolution)) {
        this.recreate()
      }
    },
    url: 'recreate',
    typeNames: 'recreate',
    layerStyle (style) {
      if (this.layer) {
        this.layer.setStyle(style)
      }
    }
  },
  created () {
    this.getMap().then(map => {
      this.map = map

      this.resolution = this.map.getView().getResolution()
      this.resolutionListenerKey = this.map.getView().on('change:resolution', () => {
        this.resolution = this.map.getView().getResolution()
      })

      this.recreate()
    })
  },
  beforeDestroy () {
    if (this.layer) {
      this.map.getLayers().remove(this.layer)
    }
    if (this.resolutionListenerKey) {
      unByKey(this.resolutionListenerKey)
    }
  },
  render () {
    return null
  }
}
