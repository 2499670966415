<template>
  <div class="button-container">
    <span class="button-list">
      <QuickModeButton
        :description="SR_FieldRecordSystem.drawArea" :class="{'button-active': mode === 'addPolygon'}"
        :icon="icons[0]"
        @click="mode = 'addPolygon'"
      />
      <QuickModeButton
        :description="SR_FieldRecordSystem.importArea" :class="{'button-active': showUploadModal}"
        :icon="icons[1]"
        @click="showUploadModal = true"
      />

      <QuickModeButton
        v-if="$can('See.Frs.ExperimentalFeatures')" :description="SR_FieldRecordSystem.showOfficialAreas"
        :class="{'button-active': mode === 'borderRecognition_ixmap'}"
        :icon="icons[3]"
        @click="mode = 'borderRecognition_ixmap'"
      />

      <div class="divider" />

      <QuickModeButton
        :description="SR_FieldRecordSystem.edit" :class="{'button-active': mode === 'edit'}"
        :icon="icons[5]"
        @click="mode = 'edit'"
      />
      <QuickModeButton
        :description="SR_FieldRecordSystem.select" :class="{'button-active': mode === 'selection'}"
        :icon="icons[6]"
        @click="mode = 'selection'"
      />
      <QuickModeButton
        :description="SR_FieldRecordSystem.deleteSelection" class="button-click"
        :icon="icons[7]"
        @click="deleteSelection"
      />

      <div class="divider" />

      <QuickModeButton :description="SR_FieldRecordSystem.save" save @click="onSaveClick" />
      <QuickModeButton
        :description="SR_FieldRecordSystem.discardChanges" remove
        class="button-click"
        :icon="icons[8]" @click="onAbortEditing"
      />
      <QuickModeButton
        v-if="$can('See.Frs.ExperimentalFeatures')" :description="SR_FieldRecordSystem.showRoads"
        :class="showRoadsActive"
        :icon="icons[9]" @click="roads = !roads"
      />
      <QuickModeButton
        :description="SR_FieldRecordSystem.toExpertMode" class="button-click"
        :icon="icons[10]"
        @click="switchToExpertMode(activeFeatures)"
      />
    </span>

    <GeometryImportModal v-model="showUploadModal" :title="SR_Common.GeometryImportModal_title" @load="addFeatures" />
    <FieldEditingSaveModal v-model="showSaveModal" @confirm="save" />
  </div>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'
import {faVectorSquare, faCloudUploadAlt, faCrosshairs, faStopCircle, faPen, faTimesCircle, faRoad, faEllipsisH} from '@fortawesome/free-solid-svg-icons'
import {faHandPointer, faHandPaper, faTrashAlt} from '@fortawesome/free-regular-svg-icons'

import {mapFormFields, mapResources} from '@helpers/vuex'
import {notifications} from 'src/js/infrastructure'
import {geoJsonFormat, dissolveMultiGeometries, toWkt, combineToMultiPolygon} from '@helpers/openlayers/features'

import GeometryImportModal from '@components/forms/GeometryImportModal'

import QuickModeButton from './widget/QuickModeButton'
import FieldEditingSaveModal from '../FieldEditingSaveModal'

import PermissionMixin from '@mixins/PermissionMixin'

export default {
  inject: [
    'getMap'
  ],
  components: {
    QuickModeButton,
    GeometryImportModal,
    FieldEditingSaveModal
  },
  mixins: [
    PermissionMixin
  ],
  data () {
    return {
      showUploadModal: false,
      showSaveModal: false,
      icons: [
        faVectorSquare,
        faCloudUploadAlt,
        faCrosshairs,
        faHandPointer,
        faStopCircle,
        faPen,
        faHandPaper,
        faTrashAlt,
        faTimesCircle,
        faRoad,
        faEllipsisH
      ]
    }
  },
  computed: {
    ...mapResources([
      '@frs.SR_FieldRecordSystem',
      'Common.SR_Common'
    ]),
    ...mapFormFields('fieldRecordSystem/map', [
      'editing.mode',
      'editing.abortEditing',
      'editing.roads',
      'editing.activeFeatures'
    ]),
    ...mapState('fieldRecordSystem', {
      undoBuffer: state => state.map.editing.undoBuffer
    }),
    showRoadsActive () {
      return this.roads ? 'button-active' : ''
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem', [
      'getCultivationUpdateCandidates',
      'saveFieldGeometry'
    ]),
    ...mapMutations('fieldRecordSystem/map', [
      'recordFeatures',
      'setSelectedIds',
      'setShowImportSimplificationHint'
    ]),
    ...mapActions('fieldRecordSystem/map', [
      'switchToExpertMode',
      'deleteSelection',
      'stopEditing',
      'simplifyGeometry',
      'focus'
    ]),
    onSaveClick () {
      this.$emit('saving', true)
      this.getCultivationUpdateCandidates()
        .then(candidatesExist => {
          if (candidatesExist) {
            this.showSaveModal = true
          } else {
            return this.save()
          }
        })
        .catch(error => {
          notifications.error(this.SR_FieldRecordSystem.AreaSaveError)
          console.error(error)
        })
        .then(() => {
          this.$emit('saving', false)
        })
    },
    save (selectedCultivations = []) {
      this.$emit('saving', true)

      return this.stopEditing().then(features => {
        const polygons = features.filter(f => f.getGeometry().getType() === 'Polygon')
        const wkt = polygons.length ? toWkt(combineToMultiPolygon(polygons)) : null
        return this.saveFieldGeometry({
          wkt,
          cultivationIds: selectedCultivations.map(x => x.id)
        })
      })
        .then(() => {
          notifications.success(this.SR_FieldRecordSystem.SavedFieldGeometry)
        })
        .catch(error => {
          console.error(error)
          notifications.error(this.SR_FieldRecordSystem.AreaSaveError)
        })
        .finally(() => {
          this.$emit('saving', false)
        })
    },
    addFeatures (geojson) {
      let features = geoJsonFormat.readFeatures(geojson)
      features = dissolveMultiGeometries(features)
        .map(feature => geoJsonFormat.writeFeatureObject(feature))

      this.recordFeatures()
      this.showUploadModal = false

      this.activeFeatures = this.activeFeatures.concat(features)

      this.$nextTick().then(() => {
        this.setSelectedIds(features.map(x => x.properties.id))

        this.simplifyGeometry()

        this.setShowImportSimplificationHint(true)
        // zoom to the imported feature
        this.focus(this.activeFeatures)
      })
    },
    onAbortEditing () {
      if (this.undoBuffer.length > 0) {
        this.abortEditing = true
      } else {
        this.stopEditing()
      }
    }
  }
}
</script>

<style scoped>
.button-container {
  display: flex;
  flex-direction: column;
}
.expert-mode {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
}
.button-list {
  display: flex;
}
.divider {
  width: 1px;
  height: 48px;
  margin: 0 6px;
  background-color: #e7e7e7;
}
</style>

<style lang="scss" scoped>
.button-active ::v-deep .button {
  background-color: #7fbde9;
}
.button-click ::v-deep .button:active {
  background-color: #aad3fb;
  border: 2px solid #4e75b9;
}
.button-active ::v-deep .button:hover {
  background-color: #aad3fb;
  border: 2px solid #4e75b9;
}
</style>
