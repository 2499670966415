<template lang="html">
  <div>
    <BsModal v-model="modelProxy" :loading="downloading" @shown="setFilename">
      <template #title>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.{{ downloadMapType }}</IxRes>
      </template>
      <div :class="hasValidUnits ? 'selection-info-box-container' : ''">
        <div :class="hasValidUnits ? 'selection-converter' : ''">
          <VueSelectize
            v-model="selectedConverter" required
            :options="validConverters" :fields="fields"
            :placeholder="$i18n.translate('Controls.Placeholders.SR_Placeholders.ComboboxFileFormat')"
            allow-empty name="converterType"
          >
            <template #label>
              <IxRes>Common.SR_Common.SelectFileType</IxRes>
            </template>
          </VueSelectize>

          <VueSelectize
            v-if="hasValidUnits"
            v-model="selectedUnit" required
            :options="validUnits"
            allow-empty
            name="unit"
          >
            <template #label>
              <IxRes>download.labels.unitSelection</IxRes>
            </template>
          </VueSelectize>
        </div>
        <HelpBox v-if="unitInfoText && mapType === 'applicationMap'">
          {{ unitInfoText }}
        </HelpBox>
      </div>

      <component
        :is="converterComponents[selectedConverter]" v-if="selectedConverter"
        :key="selectedConverter"
        :format="selectedConverter"
      />

      <BsCheckbox v-if="isShapeConverterAndMultiDownload" v-model="multipleFiles">
        <IxRes>download.labels.multipleFiles</IxRes>
      </BsCheckbox>
      <BsCheckbox v-if="seeIncludeFieldBorderCheckbox" v-model="includeFieldBorder">
        <IxRes>Common.SR_Common.Labels.IncludeFieldBorder</IxRes>
      </BsCheckbox>
      <MapPaymentTermsCheckbox v-if="mapType === 'applicationMap'" v-model="termsAccepted" />
      <div slot="footer">
        <IxButton large :disabled="mapType === 'applicationMap' && !termsAccepted" @click="onDownload">
          <IxRes v-if="mapType == 'fieldBorder'">Common.SR_Common.FieldBorder.Download</IxRes>
          <IxRes v-else-if="mapType == 'applicationMap'">Common.SR_Common.ApplicationMap.Download</IxRes>
          <IxRes v-else-if="mapType == 'nutrientMap'">Common.SR_Common.NutrientMap.Download</IxRes>
          <IxRes v-else-if="mapType == 'zoneMap'">Common.SR_Common.ZoneMap.Download</IxRes>
          <IxRes v-else-if="mapType == 'soilMap' && selectedConverter === 'GeoTiffConverter'">Common.SR_Common.Button.SensorData.Download</IxRes>
          <IxRes v-else>Common.SR_Common.Download</IxRes>
        </IxButton>
        <IxButton large cancel @click="$emit('input', false)">
          <IxRes>Common.SR_Common.Cancel</IxRes>
        </IxButton>
      </div>
      <a
        ref="a" style="display: none"
        type="application/zip"
        target="_blank" data-ajax="false"
      />
    </BsModal>
  </div>
</template>

<script>
import moment from 'moment'
import {mapState, mapGetters, mapActions} from 'vuex'

import {mapFormFields, modelProxy} from '@helpers/vuex'
import {notifications} from 'src/js/infrastructure'

import MapPaymentTermsCheckbox from '@components/MapPaymentTermsCheckbox'
import BsModal from '@components/bootstrap/BsModal'
import BsCheckbox from '@components/bootstrap/BsCheckbox'
import IxButton from '@components/IxButton'
import VueSelectize from '@components/VueSelectize'

import IsoXmlConverter from './IsoXmlConverter'
import ShapeConverter from './ShapeConverter'
import GeoTiffConverter from './GeoTiffConverter'

import FormPartMixin from '@components/forms/FormPartMixin'
import HelpBox from '../help/HelpBox'

/**
 * This component is part of the standard download flow.
 * It handles selecting a download format and supplying format-specific options, as well as starting the actual file download for the user
 *
 * for more details about the download flow see iXmap.Solutions.WebApp.Areas.DataTransfer.Controllers.DownloadController
 */
export default {
  components: {
    HelpBox,
    BsModal,
    VueSelectize,
    IxButton,
    IsoXmlConverter,
    GeoTiffConverter,
    ShapeConverter,
    MapPaymentTermsCheckbox,
    BsCheckbox
  },
  mixins: [
    FormPartMixin
  ],
  props: {
    value: Boolean
  },
  data () {
    return {
      termsAccepted: false,
      converterComponents: {
        ShapeConverter: 'ShapeConverter', // Shape
        ApplicationMapShapeConverter: 'ShapeConverter', // Shape
        FmxConverter: 'ShapeConverter', // Shape für Trimble
        TmxConverter: 'ShapeConverter', // Dateiformat für Trimble® (TmX)
        TrimbleAgGPSConverter: 'ShapeConverter', // Dateiformat für Trimble® (AgGPS)
        IsoxmlConverter: 'IsoXmlConverter',
        AgroSenseSmartConverter: 'ShapeConverter', // AgroSenseSmart
        IsoxmlAdvancedConverter: 'IsoXmlConverter', // ISOXML (erweitert)
        ISOxmlTopconConverter: 'IsoXmlConverter', // ISOXML für TOPCON
        ShapeTopconConverter: 'ShapeConverter', // Shape für TOPCON
        JohnDeereConverter: 'ShapeConverter', // Shape für John Deere
        IsoXmlIntellview4Converter: 'IsoXmlConverter', // IsoXml Intelliview 4
        TopConConverter: 'ShapeConverter', // TopCon
        KmlConverter: 'ShapeConverter', // KML
        KmzConverter: 'ShapeConverter', // KMZ
        GeoTiffConverter: 'GeoTiffConverter'
      },
      downloading: false
    }
  },
  computed: {
    ...mapFormFields('fieldRecordSystem/download', [
      'selectedConverter',
      'selectedUnit',
      'fileName',
      'mapType',
      'includeFieldBorder',
      'multipleFiles'
    ]),
    ...mapState('fieldRecordSystem/download', {
      mapIds: state => state.selectedMapIds,
      validUnits: state => state.validUnits,
      srcUnit: state => state.srcUnit
    }),
    ...mapGetters('fieldRecordSystem/mapManagement', [
      'mapsByType'
    ]),
    ...mapGetters('fieldRecordSystem/download', [
      'validConverters',
      'validShapeConvertersForMultiDownload',
      'converterWithIncludeFieldBorderOption'
    ]),
    seeIncludeFieldBorderCheckbox () {
      return this.mapType === 'applicationMap' && this.converterWithIncludeFieldBorderOption.includes(this.selectedConverter)
    },
    hasValidUnits () {
      return this.mapType === 'applicationMap' && this.validUnits.length
    },
    unitInfoText () {
      if (this.srcUnit !== this.selectedUnit && this.mapType === 'applicationMap') {
        return this.$i18n.translate('download.infos.unitConvertInfo', {destUnit: this.selectedUnit})
      }
      return null
    },
    fields () {
      return {
        text: converterType => this.$i18n.translate(`Enums.SR_Enums.ConverterCollection_${converterType}`),
        value: value => value
      }
    },
    downloadMapType () {
      return 'Download' + this.mapType.charAt(0).toUpperCase() + this.mapType.slice(1)
    },
    isShapeConverterAndMultiDownload () {
      return (this.validShapeConvertersForMultiDownload.includes(this.selectedConverter) && this.mapIds.length > 1)
    },
    modelProxy
  },
  methods: {
    ...mapActions('fieldRecordSystem/download', [
      'download'
    ]),
    setFilename () {
      switch (this.selectedConverter) {
      case 'ShapeConverter':
      case 'ApplicationMapShapeConverter':
      case 'FmxConverter':
      case 'ShapeTopconConverter':
      case 'JohnDeereConverter':
        this.fileName = moment().format('YYYY-MM-DD-HH-mm-ss')
        break
      case 'TopConConverter':
        this.fileName = 'Boundaries'
        break
      case 'TmxConverter':
      case 'TrimbleAgGPSConverter':
        this.fileName = 'TASK'
        break
      case 'AgroSenseSmartConverter':
        this.fileName = '1_'
        break
      case 'IsoXmlIntellview4Converter':
      case 'ISOxmlTopconConverter':
      case 'IsoxmlConverter':
      case 'IsoxmlAdvancedConverter':
        this.fileName = 'TASKDATA'
        break
      case 'KmzConverter':
      case 'KmlConverter':
      case 'GeoTiffConverter':
        this.fileName = this.mapIds.length > 1
          ? moment().format('YYYY-MM-DD-HH-mm-ss')
          : this.mapsByType[this.mapType].find(map => map.id === this.mapIds[0]).name
        break
      default:
        this.fileName = ''
        break
      }
    },
    onDownload () {
      this.downloading = true

      this.validate()

      // nextTick to get computed updates for state
      this.$nextTick()
        .then(() => {
          if (this.state !== 'success') return

          return this.download()
            .then(path => {
              this.$refs.a.href = `/Download/Temp/${path}`
              this.$refs.a.download = path.split(/\\/)[1]
              this.$refs.a.click()
              notifications.success(this.$i18n.translate('Messages.State.SR_StateMessages.DownloadSuccess'))
              this.modelProxy = false
            })
            .catch((e) => {
              notifications.error(this.$i18n.translate('Messages.State.SR_StateMessages.DownloadError'))
              console.error(`${this.$i18n.translate('Messages.State.SR_StateMessages.DownloadError')} - ${e.message}`)
            })
        })
        .finally(() => {
          this.downloading = false
          this.termsAccepted = false
        })
    }
  },
  watch: {
    selectedConverter: {
      immediate: true,
      handler () {
        this.setFilename()
        this.multipleFiles = true
        this.includeFieldBorder = false
      }
    }
  }
}
</script>

<style lang="scss">
.selection-info-box-container {
  display: flex;
  flex-direction: column;

  .selection-converter {
    display: flex;

    :first-child {
      flex: 2;
      margin-right: 4px;
    }
    :last-child {
      flex: 1;
    }
  }
}
</style>
