import Style from 'ol/style/Style'
import Text from 'ol/style/Text'
import Stroke from 'ol/style/Stroke'
import Fill from 'ol/style/Fill'
import RegularShape from 'ol/style/RegularShape'
import MultiPoint from 'ol/geom/MultiPoint'
import Circle from 'ol/style/Circle'

import {flatten} from '@helpers/reducers'
import {setLabel, setColor, modifierStack, labelText} from '@helpers/openlayers/styles'
import {geoJsonFormat} from '@helpers/openlayers/features'
import {computeSizeFormatted} from '@helpers/openlayers/format'

const outline = new Style({
  stroke: new Stroke({
    color: 'rgba(1, 131, 251, 1)',
    width: 2
  }),
  fill: new Fill({
    color: 'rgba(1, 131, 251, 0.2)'
  })
})

const vertices = new Style({
  image: new RegularShape({
    radius1: 10,
    radius2: 1,
    angle: Math.PI / 4,
    points: 4,
    fill: new Fill({
      color: 'rgba(255, 75, 51, 1)'
    })
  }),
  geometry (feature) {
    if (feature.getGeometry().getType() === 'Point') {
      // when adding a point there there is no MultiPolygon
      return feature.getGeometry()
    }
    const geometry = feature.getGeometry()
    let coordinates
    switch (geometry.getType()) {
    case 'Point': {
      return null
    }
    case 'LineString': {
      coordinates = geometry.getCoordinates()
      break
    }
    case 'Polygon': {
      coordinates = geometry.getCoordinates().reduce(flatten, [])
      break
    }
    }
    return new MultiPoint(coordinates)
  }
})

const geometrySize = new Style({
  text: new Text({
    stroke: new Stroke({
      color: 'black',
      width: 2
    }),
    fill: new Fill({
      color: 'rgb(170, 170, 170)'
    }),
    text: '12.47 ha',
    textAlign: 'center',
    font: '18px CalibreWeb',
    offsetY: 20,
    overflow: true
  })
})

export const setGeometrySizeLabel = setLabel(feature => {
  if (feature.getGeometry().getType() === 'Point') return ''

  return computeSizeFormatted(geoJsonFormat.writeGeometryObject(feature.getGeometry()))
})

const inactive = new Style({
  stroke: new Stroke({
    color: 'rgba(180, 180, 180, 1)',
    width: 2
  }),
  fill: new Fill({
    color: 'rgba(128, 128, 128, 0.5)'
  }),
  text: labelText({color: 'rgb(35, 35, 35)', textColor: 'rgba(255, 255, 255, 0.8)'})
})

export const inactiveStyle = modifierStack(inactive, [
  setLabel(feature => feature.get('name') || '')
])

export const editing = (feature, resolution) => {
  setGeometrySizeLabel(geometrySize, feature, resolution)

  return [
    outline,
    vertices,
    geometrySize
  ]
}

export const holes = [
  new Style({
    image: new Circle({
      fill: new Fill({
        color: 'rgba(0, 255, 0, 0.5)'
      }),
      radius: 3
    }),
    stroke: new Stroke({
      color: 'rgba(0, 255, 0, 0.5)',
      width: 2
    }),
    fill: new Fill({
      color: 'rgba(0, 255, 0, 0.2)'
    })
  })
]

const guideFill = new Fill({
  color: 'rgba(224, 188, 0, 0.2)'
})

const guideStroke = new Stroke({
  width: 2,
  color: 'rgb(224, 188, 0)',
  lineCap: 'butt',
  lineJoin: 'miter',
  lineDash: [5, 5]
})

const guideBaseStyle = new Style({
  image: new Circle({
    radius: 5,
    fill: guideFill,
    stroke: guideStroke
  }),
  fill: guideFill,
  stroke: guideStroke
})

export const guides = guideBaseStyle

const previousMeasurementsBase = new Style({
  fill: new Fill({
    color: 'rgba(255, 255, 255, 0.2)'
  }),
  stroke: new Stroke({
    color: 'rgba(224, 188, 0, 1)',
    width: 2
  }),
  image: new Circle({
    radius: 7,
    fill: new Fill({
      color: 'rgba(224, 188, 0, 1)'
    })
  })
})

export const measurementPreview = new Style({
  fill: new Fill({
    color: 'rgba(224, 188, 0, 0.3)'
  }),
  stroke: new Stroke({
    color: 'rgba(224, 188, 0, 1)',
    lineDash: [10, 10],
    width: 2
  }),
  image: new Circle({
    radius: 5,
    stroke: new Stroke({
      color: 'rgba(224, 188, 0, 1)'
    })
  })
})

const measurementSize = new Style({
  text: new Text({
    stroke: new Stroke({
      color: 'black',
      width: 2
    }),
    fill: new Fill({
      color: 'rgb(225, 225, 225)'
    }),
    text: '12.47 ha',
    textAlign: 'center',
    font: '14px CalibreWeb',
    overflow: true
  })
})

export const previousMeasurements = (feature, resolution) => {
  setGeometrySizeLabel(measurementSize, feature, resolution)

  return [
    previousMeasurementsBase,
    measurementSize
  ]
}

export const cutLine = new Style({
  image: new Circle({
    stroke: new Stroke({
      color: 'rgba(254, 77, 46, 1)',
      width: 2
    }),
    fill: new Fill({
      color: 'rgba(254, 77, 46, 1)'
    }),
    radius: 3
  }),
  stroke: new Stroke({
    color: 'rgba(254, 77, 46, 1)',
    width: 2
  }),
  fill: new Fill({
    color: 'rgba(254, 77, 46, 1)'
  })
})

export const cutPreviewPolygons = new Style({
  stroke: new Stroke({
    color: 'rgba(122, 196, 15, 1)',
    width: 2
  }),
  fill: new Fill({
    color: 'rgba(122, 196, 15, 0.3)'
  }),
  text: new Text({
    stroke: new Stroke({
      color: 'black',
      width: 2
    }),
    fill: new Fill({
      color: 'white'
    }),
    text: '12.47 ha',
    textAlign: 'center',
    font: '20px CalibreWeb',
    overflow: true
  })
})

const indexedStyles = [
  ['rgb(129, 27, 184)', 'rgba(129, 27, 184, 0.3)'],
  ['rgb(168, 58, 47)', 'rgba(168, 58, 47, 0.3)'],
  ['rgb(129, 227, 183)', 'rgba(129, 227, 183, 0.3)'],
  ['rgb(104, 122, 241)', 'rgba(104, 122, 241, 0.3)'],
  ['rgb(98, 204, 48)', 'rgba(98, 204, 48, 0.3)']
].map(([stroke, fill]) => ({
  stroke,
  fill
}))

const defaultColor = {
  stroke: 'rgba(231, 139, 44, 1)',
  fill: 'rgba(231, 139, 44, 0.3)'
}

export const cutPreview = modifierStack(cutPreviewPolygons, [
  setGeometrySizeLabel,
  setColor(feature => {
    return indexedStyles[feature.get('index')] || defaultColor
  })
])
