<template lang="html">
  <div>
    <IxVectorLayer
      :features="fieldFeatures" layer-id="fields"
      :vector-style="styles.field"
      :cluster-style="styles.fieldCluster"
    />
  </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import {mapUiFlags} from '@frs/helpers/ui'

import {field, fieldWithCode, fieldCluster} from '@frs/map-styles'

import IxVectorLayer from '@components/map/IxVectorLayer'

export default {
  components: {
    IxVectorLayer
  },
  computed: {
    ...mapUiFlags([
      'dashboard.showFieldCodes'
    ]),
    ...mapState('fieldRecordSystem', {
      fieldDataLookup: state => state.data.field
    }),
    ...mapState('fieldRecordSystem/navigation', [
      'simplifiedGeometries'
    ]),
    ...mapGetters('fieldRecordSystem/navigation', [
      'subTreeFields'
    ]),
    fieldFeatures () {
      return this.subTreeFields.map(({id, name}) => ({
        type: 'Feature',
        properties: {id, name, code: this.fieldDataLookup[id].code},
        geometry: this.simplifiedGeometries[id]
      })).filter(x => x.geometry)
    },
    styles () {
      return {
        field: this.showFieldCodes ? fieldWithCode : field,
        fieldCluster
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
