<template lang="html">
  <div class="quickmode-tool">
    <div class="geometry-plugin-component">
      <CancelEditButtons v-if="abortEditing && undoBuffer.length > 0" />
      <QuickModeEditingButtons v-else @saving="saving = $event" />

      <FrsLoadingIndicator :loading="saving" small />
    </div>

    <IxVectorLayer
      v-if="!['edit'].includes(mode)"
      :features="activeFeatures"
      :vector-style="vectorStyle"
      layer-id="editing"
    />

    <IxWFSLayer
      v-if="roads"
      url="/api/v2/entities/fields/geodata/roads"
      :layer-style="roadStyle"
      :max-resolution="10"
    />

    <QuickModeInteractions />
  </div>
</template>

<script>
import {mapState} from 'vuex'

import {roads, fieldBorderSuggestion} from '@frs/map-styles'
import {editing} from '@components/map/interactions/styles'

import CancelEditButtons from '@frs/components/geometry-editing/quick-mode-editing/CancelEditButtons'
import QuickModeEditingButtons from '@frs/components/geometry-editing/quick-mode-editing/QuickModeEditingButtons'
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'

import ControlMixin from '@components/map/mixins/ControlMixin'
import IxVectorLayer from '@components/map/IxVectorLayer'
import IxWFSLayer from '@components/map/IxWFSLayer'
import QuickModeInteractions from './QuickModeInteractions'

export default {
  components: {
    CancelEditButtons,
    QuickModeEditingButtons,
    IxWFSLayer,
    IxVectorLayer,
    QuickModeInteractions,
    FrsLoadingIndicator
  },
  mixins: [
    ControlMixin
  ],
  data () {
    return {
      saving: false
    }
  },
  computed: {
    ...mapState('fieldRecordSystem/map', {
      abortEditing: state => state.editing.abortEditing,
      undoBuffer: state => state.editing.undoBuffer,
      mode: state => state.editing.mode,
      roads: state => state.editing.roads,
      activeFeatures: state => state.editing.activeFeatures
    }),
    roadStyle () {
      return roads
    },
    borderStyle () {
      return fieldBorderSuggestion
    },
    vectorStyle () {
      return editing
    }
  }
}
</script>

<style lang="scss" scoped>
.quickmode-tool {
  position: absolute;
  bottom: 10px;
  width: 100%;

  display: flex;
  justify-content: center;
  pointer-events: none;

  .geometry-plugin-component {
    position: relative;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 8px;
    border-radius: 6px;
    pointer-events: all;
  }
}
</style>
