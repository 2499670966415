<template lang="html">
  <LocationSearchInput
    v-model="searchString"
    class="location-search-control"
    :suggestions="resources"
    :placeholder="SR_Placeholders.SearchFor"
    @submit="goToFoundExtent" @input="search"
  />
</template>

<script>
import {transformExtent} from 'ol/proj'

import {mapResources} from '@helpers/vuex'
import {notifications} from 'src/js/infrastructure'

import LocationSearchInput from '@components/forms/LocationSearchInput'
import ControlMixin from '@components/map/mixins/ControlMixin'

export default {
  components: {
    LocationSearchInput
  },
  mixins: [
    ControlMixin
  ],
  data () {
    return {
      searchString: '',
      controlClasses: ['ol-location-search'],
      location_WGS84: '',
      location_900913: '',
      resources: []
    }
  },
  computed: {
    ...mapResources([
      'Controls.Placeholders.SR_Placeholders',
      'Messages.Error.SR_ErrorMessages',
      'Messages.Info.SR_InfoMessages'
    ])
  },
  methods: {
    search () {
      if (this.searchString) {
        const url = `https://dev.virtualearth.net/REST/v1/Locations?format=json&key=Au0ifSPwzTE-2YGjxsi1nnQEXlfmOMj9Qqj3d0htTG38I-mxsXzbsviDpmd8fgUs&q=${encodeURIComponent(this.searchString)}`

        const ajaxData = {
          type: 'POST',
          contentType: 'application/json; charset=utf-8',
          url: url,
          success: this.searchCallback,
          dataType: 'jsonp',
          jsonp: 'jsonp'
        }
        $.ajax(ajaxData)
      }
    },
    searchCallback (data) {
      if (data.statusCode !== 200) {
        notifications.error('', this.SR_ErrorMessages.BingServerError)
        return
      }
      if (this.resources && this.resources.length > 0) {
        const resource = this.resources.filter(x => (x.address.formattedAddress + ', ' + x.address.adminDistrict2) === this.searchString)
        if (resource[0]) {
          this.goToResource(resource[0])
        }
      }
      this.resources = data.resourceSets[0].resources
    },
    goToFoundExtent () {
      if (!this.resources || !this.resources[0] || this.resources[0].estimatedTotal === 0) {
        notifications.warning('', this.SR_InfoMessages.NoLocationFound)
        return
      }
      this.extent_WGS84 = this.transformBingBBoxToOlExtent(this.resources[0].bbox)
      this.extent_900913 = transformExtent(this.extent_WGS84, 'EPSG:4326', 'EPSG:900913')
      const view = this.map.getView()
      view.fit(this.extent_900913)
    },
    goToResource (resource) {
      this.extent_WGS84 = this.transformBingBBoxToOlExtent(resource.bbox)
      this.extent_900913 = transformExtent(this.extent_WGS84, 'EPSG:4326', 'EPSG:900913')
      const view = this.map.getView()
      view.fit(this.extent_900913)
    },
    transformBingBBoxToOlExtent (bbox) {
      let olExtent = []
      olExtent[0] = bbox[1]
      olExtent[1] = bbox[0]
      olExtent[2] = bbox[3]
      olExtent[3] = bbox[2]
      return olExtent
    }
  }
}
</script>

<style lang="scss" scoped>
.location-search-control {
  top: 6px;
  left: 36px;
  width: 300px !important; // workaround for internal styling issues
}
</style>
