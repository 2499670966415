<template lang="html">
  <div class="sidebar" :class="{expanded, wide, [direction]: direction}">
    <slot />
    <div class="expander">
      <div class="handle" @click="$emit('input', !expanded)">
        <i class="fa fa-lg" :class="[expanderIcon, !expanded ? 'text-muted' : '']" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'expanded'
  },
  props: {
    expanded: Boolean,
    wide: Boolean,
    direction: String
  },
  computed: {
    expanderIcon () {
      const selectRightError = this.direction === 'right' ? this.expanded : !this.expanded
      if (selectRightError) {
        return 'fa-arrow-right'
      }
      return 'fa-arrow-left'
      // switch (this.direction) {
      // case 'left':
      //   return this.expanded ? 'fa-caret-left' : 'fa-caret-right'
      // case 'right':
      //   return this.expanded ? 'fa-caret-right' : 'fa-caret-left'
      // }
    }
  }
}
</script>

<style lang="scss" scoped>
$standardWidth: 400px;
$wideWidth: 800px;
$expanderWidth: 40px;
$peekWidth: 25px;
$sidebarColor: rgba(255, 255, 255, 0.95);
.sidebar {
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: $sidebarColor;
  transition: width 0.25s, left 0.2s, right 0.2s;

  top: 0;
  bottom: 0;
  width: $standardWidth;

  &.wide {
    width: $wideWidth;
  }

  .expander {
    top: 0;
    bottom: 0;
  }

  &.left {
    left: -$standardWidth + $peekWidth;
    &.wide {
      left: -$wideWidth + $peekWidth;
    }
    .expander {
      right: -$expanderWidth;

      .handle {
        border-right: 1px solid darkgray;
      }
    }
  }

  &.right {
    right: -$standardWidth + $peekWidth;
    &.wide {
      right: -$wideWidth + $peekWidth;
    }
    .expander {
      left: -$expanderWidth;

      .handle {
        border-left: 1px solid darkgray;
      }
    }
  }
}
.sidebar.expanded{
  &.left {
    left: 0;
  }
  &.right {
    right: 0;
  }
}
.expander {
  position: absolute;
  display: flex;
  align-items: center;
  pointer-events: none;

  transition: opacity 0.25s;
  opacity: 1;

  .handle {
    width: $expanderWidth;
    height: $expanderWidth;
    border-top: 1px solid darkgray;
    border-bottom: 1px solid darkgray;
    // color: darkgray;
    background-color: $sidebarColor;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: auto;
    cursor: pointer;

    transition: color 0.25s;

    &:hover {
      // color: black;
    }
  }
}

</style>
