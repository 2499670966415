import Control from 'ol/control/Control'

export default {
  inject: [
    'getMap'
  ],
  data () {
    return {
      controlClasses: [],
      map: null
    }
  },
  created () {
    this.getMap().then(map => {
      this.map = map

      const element = document.createElement('div')
      element.classList.add('ol-unselectable')
      element.style.top = element.style.right = element.style.bottom = element.style.left = 0
      element.style.position = 'absolute'
      element.style.pointerEvents = 'none'

      this.control = new Control({element})

      if (!this.$el.parentNode) {
        this.control.element.appendChild(this.$el)
      }

      this.map.addControl(this.control)
    })
  },
  mounted () {
    this.$el.style.pointerEvents = 'initial'

    if (!this.control) {
      this.$el.remove()
    } else if (!this.control.element.childElementCount) {
      this.$el.remove()
      this.control.element.appendChild(this.$el)
    }
  },
  updated () {

  },
  beforeDestroy () {
    if (this.control) {
      this.map.removeControl(this.control)
    }
  }
}
