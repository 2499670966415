import {format} from 'src/js/i18n/conversion'
import translateUnit from 'src/js/i18n/translate-unit'

export const formatLength = (length) => {
  return length >= 100 ? `${format(length / 1000)} ${translateUnit('km')}` : `${format(length)} ${translateUnit('m')}`
}

export const formatArea = (area) => {
  return area >= 1000 ? `${format(area / 10000)} ${translateUnit('ha')}` : `${format(area)} ${translateUnit('m²')}`
}

export const computeSizeFormatted = geometry => {
  const type = geometry.type

  return type === 'Polygon' || type === 'MultiPolygon' || type === 'LineString' || type === 'MultiLineString' || type === 'Point' || type === 'MultiPoint'
    ? format(geometry)
    : ''
}
