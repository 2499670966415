<template lang="html">
  <BsModal v-model="show" :title="title">
    <slot />
  </BsModal>
</template>

<script>
import BsModal from '@components/bootstrap/BsModal'

export default {
  components: {
    BsModal
  },
  props: {
    title: {},
    keyCode: Number
  },
  data () {
    return {
      show: false,
      listener: null
    }
  },
  created () {
    this.listener = document.body.addEventListener('keydown', event => {
      if (event.keyCode === this.keyCode) {
        this.show = !this.show
      }
    })
  },
  beforeDestroy () {
    document.body.removeEventListener('keydown', this.listener)
  }
}
</script>

<style lang="css">
</style>
