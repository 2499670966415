<template lang="html">
  <div>
    <TextInput
      v-model="fileName" required
      name="fileName"
      :disabled="true"
    >
      <template #label>
        <IxRes>Common.SR_Common.FileName</IxRes>
      </template>
    </TextInput>
  </div>
</template>

<script>
import {mapFormFields} from '@helpers/vuex'

import TextInput from '@components/forms/TextInput'

export default {
  components: {
    TextInput
  },
  computed: {
    ...mapFormFields('fieldRecordSystem/download', [
      'fileName'
    ])
  }
}
</script>

<style lang="css">
</style>
