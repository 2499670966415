<template>
  <div class="cancel-edit-buttons">
    <IxButton ban large @click="continueWorking">
      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.continueWorking</IxRes>
    </IxButton>
    <IxButton
      remove large
      icon="check"
      @click="confirmCancel"
    >
      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.confirmCancel</IxRes>
    </IxButton>
  </div>
</template>

<script>
import {mapFormFields} from '@helpers/vuex'
import {mapActions} from 'vuex'

import IxButton from '@components/IxButton'

export default {
  components: {
    IxButton
  },
  computed: {
    ...mapFormFields('fieldRecordSystem/map', [
      'editing.abortEditing'
    ])
  },
  methods: {
    ...mapActions('fieldRecordSystem/map', [
      'stopEditing'
    ]),
    continueWorking () {
      this.abortEditing = false
    },
    confirmCancel () {
      this.abortEditing = false
      this.stopEditing()
    }
  }
}
</script>

<style lang="scss" scoped>
.cancel-edit-buttons {
  display: flex;

  > *:not(:last-child) {
    margin-right: 6px;
  }
}
</style>
