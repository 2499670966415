<template lang="html">
  <div>
    <DrawInteraction
      v-if="mode === 'addPolygon'"
      type="Polygon"
      @finish="onDrawFinish"
    />
    <EditInteraction
      v-if="mode === 'edit'"
      v-model="activeFeatures"
      :no-validation="!validateWhileChange"
    />
    <EditModeHints v-if="mode === 'edit'" />
    <SelectInteraction
      v-if="mode === 'selection'"
      v-model="selectedIds"
      :features="activeFeatures"
      :hover-style="hoverStyle"
      :selection-style="selectionStyle"
      :layer-filter="layer => layer.get('id') === 'editing'"
      multiple
    />

    <IxWFSLayer
      v-if="mode === 'borderRecognition_ixmap'" ref="wfsLayer"
      url="/api/v2/entities/fields/geodata/suggestions"
      :layer-style="borderStyle"
      :max-resolution="10"
    />

    <ClickInteraction
      v-if="mode.startsWith('borderRecognition_')"
      :layer-filter="layer => layer.get('id') === 'editing'"
      @click="onClick"
    />
  </div>
</template>

<script>
import {transform} from 'ol/proj'
import {mapState, mapActions} from 'vuex'

import {mapFormFields} from '@helpers/vuex'
import {hoverStyle, selectionStyle, fieldBorderSuggestion} from '@frs/map-styles'

import DrawInteraction from '@components/map/interactions/DrawInteraction'
import EditInteraction from '@components/map/interactions/EditInteraction'
import SelectInteraction from '@components/map/interactions/SelectInteraction'
import ClickInteraction from '@components/map/interactions/ClickInteraction'
import IxWFSLayer from '@components/map/IxWFSLayer'

import EditModeHints from './widget/EditModeHints'

let count = 0

export default {
  components: {
    DrawInteraction,
    EditInteraction,
    SelectInteraction,
    ClickInteraction,
    EditModeHints,
    IxWFSLayer
  },
  computed: {
    ...mapFormFields('fieldRecordSystem/map', [
      'editing.mode',
      'editing.selectedIds',
      'editing.activeFeatures'
    ]),
    ...mapState('fieldRecordSystem', {
      validateWhileChange: state => state.map.editing.validateWhileChange
    }),
    borderStyle () {
      return fieldBorderSuggestion
    },
    selectionStyle () {
      return selectionStyle
    },
    hoverStyle () {
      return hoverStyle
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/map', [
      'borderRecognition'
    ]),
    onDrawFinish (feature) {
      feature.properties = {id: `new${count++}`}
      this.activeFeatures = [...this.activeFeatures, feature]
      this.mode = 'edit'
    },
    onClick ({coordinate}) {
      const source = this.mode.replace('borderRecognition_', '')

      const featureLoadingFunction = source === 'ixmap'
        ? this.featureLoadingFunction
        : null

      this.borderRecognition({source, coordinate, featureLoadingFunction})
    },
    featureLoadingFunction (coordinate) {
      const layer = this.$refs.wfsLayer.layer
      if (!layer) return []

      const transformed = transform(coordinate, 'EPSG:4326', 'EPSG:3857')
      return this.$refs.wfsLayer.layer.getSource().getFeaturesAtCoordinate(transformed)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
