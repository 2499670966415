<template lang="html">
  <VPopover
    :placement="placement"
    trigger="click"
    :open.sync="open"
    popover-class="error-report-popover"
  >
    <span>
      <button
        class="error-report-button" :class="{open}"
        @click.prevent
      >
        <FontAwesomeIcon :icon="icons.button" />
      </button>
    </span>
    <template #popover>
      <div class="error-report-form">
        <h3>
          <IxRes>frs.errorReporting.header</IxRes>
        </h3>

        <TextInput ref="input" v-model="errorDescription" multiline>
          <template #label>
            <IxRes>frs.errorReporting.labels.errorDescription</IxRes>
          </template>
        </TextInput>

        <HelpBox class="information">
          <IxRes>frs.errorReporting.information</IxRes>
        </HelpBox>

        <div class="buttons">
          <a
            data-ajax="false"
            class="btn btn-default" :href="downloadUrl"
            download="error-report.md" type="text/markdown; charset=UTF-8"
            target="_blank"
          >
            <IxRes>frs.errorReporting.buttons.downloadAsFile</IxRes>
          </a>

          <IxButton large @click="copyToClipboard">
            <IxRes>frs.errorReporting.buttons.copyToClipboard</IxRes>
          </IxButton>
        </div>
      </div>
    </template>
  </VPopover>
</template>

<script>
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {faBug} from '@fortawesome/free-solid-svg-icons'
import {VPopover} from 'v-tooltip'

import {notifications} from 'src/js/infrastructure'

import IxButton from '@components/IxButton'
import HelpBox from '@components/help/HelpBox'
import TextInput from '@components/forms/TextInput'

const renderObject = obj => `\`\`\`json\n${JSON.stringify(obj, null, 2)}\n\`\`\``

export default {
  components: {
    FontAwesomeIcon,
    VPopover,
    IxButton,
    HelpBox,
    TextInput
  },
  props: {
    placement: String,
    context: [Object, Array, Function]
  },
  data () {
    return {
      open: false,
      timestamp: null,
      errorDescription: '',
      compiledContext: null
    }
  },
  computed: {
    icons () {
      return {
        button: faBug
      }
    },
    meta () {
      const {errorDescription, timestamp} = this

      return {
        timestamp,
        locale: this.$store.state.i18n.locale,
        // userEmail: null, // NOTE currently not available on client
        errorDescription,
        url: location.href
      }
    },
    reportHeader () {
      return `## Automated error report\n### Basic info\n${renderObject(this.meta)}\n### Context\n`
    },
    downloadUrl () {
      return window.URL.createObjectURL(new Blob([this.reportHeader, this.compiledContext, '\n'], {type: 'application/json'}))
    }
  },
  methods: {
    compileContext () {
      const context = this.context instanceof Function ? this.context() : this.context
      const contextArray = Array.isArray(context)
        ? context
        : [context]

      const inputs = [...document.querySelectorAll('input')]
        .filter(x => x.name || x.id)
        .filter(x => window.getComputedStyle(x).display !== 'none')
        .map(input => {
          const info = {
            value: input.value
          }

          if (input.name) {
            info.name = input.name
          } else {
            info.id = input.id
          }
          return info
        })

      const inputContext = ['### Visible inputs', inputs]

      const contextText = [...contextArray, ...(inputs.length ? inputContext : [])]
        .map(part => typeof part === 'string' ? part : renderObject(part))
        .join('\n')

      return contextText
    },
    reset () {
      this.errorDescription = ''
      this.timestamp = new Date().toISOString()
      this.$refs.input.reset()
      this.compiledContext = this.compileContext()
    },
    copyToClipboard () {
      const textarea = document.createElement('textarea')
      document.body.appendChild(textarea)
      textarea.value = this.reportHeader + this.compiledContext
      textarea.select()
      document.execCommand('copy')
      document.body.removeChild(textarea)

      notifications.info(this.$i18n.translate('frs.errorReporting.notifications.copiedToClipboard'))
    }
  },
  watch: {
    open (open) {
      if (open) {
        this.reset()
      }
    },
    downloadUrl (newUrl, oldUrl) {
      if (oldUrl) {
        window.URL.revokeObjectURL(oldUrl)
      }
    }
  }
}
</script>

<style lang="scss">
.v-popover.error-report-popover {
  .v-popover-arrow {
    border-color: darkred;
  }

  .v-popover-inner {
    border: 1px solid darkred;
  }
}
</style>

<style lang="scss" scoped>
.error-report-button {
  color: lightgray;
  background-color: transparent;
  border: none;

  transition: color 0.3s;

  &:hover {
    color: gray;
  }

  &.open {
    color: darkred;
  }
}

.error-report-form {
  display: flex;
  flex-direction: column;
  max-width: 400px;

  h3 {
    margin-top: 0;
  }

  .information {
    margin-top: 1em;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
  }
}
</style>
